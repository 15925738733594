import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

const CategoryStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 4rem;
  a {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 1rem;
    align-items: center;
    padding: 5px;
    background: var(--green);
    border-radius: 2px;
    
    &[aria-current='page'] {
      background: var(--pink);
    }
  }
 @media (max-width: 709px) {
    margin-botton: 2 rem;
  }
`;


export default function CategoryFilter ({ activeCategory }) {
  // Get a list of all the toppings
  // Get a list of all the Pizzas with their toppings
  const { category, items } = useStaticQuery(graphql`
    query {
      category: allSanityCategory (
        sort: { fields: description, order: ASC }
      ) {
        nodes {
          name
          id
        }
      }
     items: allSanityItems {
        nodes {
          category {
            name
            id
          }
        }
      }
    }
  `);
  // Count how many pizzas are in each topping
  // const categoryWithCounts = countItemsInCategory(items.nodes);

  return (
    <CategoryStyles>
      <Link to="/items">
        <span className="name">All</span>
      </Link>
      <Link id="appetizers" to="/category/Appetizers" >
        <span className="name">Appetizers</span>
      </Link>
      <Link aria-current="page" to="/category/Soups%20&%20Salads">
        <span className="name">Soups & Salads</span>
      </Link>
      <Link to="/category/Lunch%20Bento">
        <span className="name">Lunch Bento</span>
      </Link>
      <Link to="/category/Light%20Lunches">
        <span className="name">Light Lunches</span>
      </Link>
      <Link to="/category/Hibachi%20Entrees">
        <span className="name">Hibachi Entrees</span>
      </Link>
      <Link to="/category/Pineapple%20Rice">
        <span className="name">Pineapple Rice</span>
      </Link>
      <Link to="/category/Noodle%20Dishes">
        <span className="name">Noodle Dishes</span>
      </Link>
      <Link to="/category/Nigiri%20Sushi">
        <span className="name">Nigiri Sushi</span>
      </Link>
      <Link to="/category/Combinations">
        <span className="name">Combinations</span>
      </Link>
      <Link to="/category/Cooked%20Maki%20Sushi">
        <span className="name">Cooked Maki Sushi</span>
      </Link>
      <Link to="/category/Raw%20Maki%20Sushi">
        <span className="name">Raw Maki Sushi</span>
      </Link>
      <Link to="/category/Poke%20Bowl">
        <span className="name">Poke Bowl</span>
      </Link>
      <Link to="/category/Soft%20Drinks%20&%20Tea">
        <span className="name">Soft Drinks & Tea</span>
      </Link>
      <Link to="/category/Beer">
        <span className="name">Beer</span>
      </Link>
      <Link to="/category/Wine">
        <span className="name">Wine</span>
      </Link>
      <Link to="/category/Desserts">
        <span className="name">Desserts</span>
      </Link>
    </CategoryStyles>
  );
}