import React from 'react';
import { graphql } from 'gatsby';
import ItemsList from '../components/ItemsList';
import CategoryFilter from '../components/CategoryFilterUpdate';
import CategoryFilterCooked from '../components/CategoryFilterCooked';
import CategoryFilterSoups from '../components/CategoryFilterSoups';
import CategoryFilterLunch from '../components/CategoryFilterLunch';
import CategoryFilterLight from '../components/CategoryFilterLight';
import CategoryFilterHibachi from '../components/CategoryFilterHibachi';
import CategoryFilterPineapple from '../components/CategoryFilterPineapple';
import CategoryFilterNoodle from '../components/CategoryFilterNoodle';
import CategoryFilterNigiri from '../components/CategoryFilterNigiri';
import CategoryFilterRaw from '../components/CategoryFilterRaw';
import CategoryFilterSoft from '../components/CategoryFilterSoft';
import CategoryFilterPoke from '../components/CategoryFilterPoke';

import Logo from '../components/Logo';


export default function ItemsPage ({ data, pageContext }) {
  const items = data.items.nodes;
  const context = pageContext.category;
  // console.log(context);

  if (context == "Appetizers") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="appetizers" src="https://cdn.sanity.io/images/pda0uygu/production/c8b95b9de1d99795b950a69535ee60fc8e1300fd-4032x3024.jpg?w=1000" />
          </div>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Beer") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Combinations") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Cooked Maki Sushi") {
    return (
      <>
        <Logo />
        <br />
        {/* <CategoryFilter activeCategory={pageContext.category} /> */}
        <CategoryFilterCooked />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="cookedmakisushi" src="https://cdn.sanity.io/images/pda0uygu/production/3bd1fa293ee6f328c71c689814bc23731547f5d1-1500x1125.jpg?w=1000" />
          </div>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Desserts") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Hibachi Entrees") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterHibachi />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="hibachientrees" src="https://cdn.sanity.io/images/pda0uygu/production/0065aa9fd76d0ac6ac23e47f78eef545f2a2b9e7-4032x3024.jpg?w=1000" />
          </div>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Light Lunches") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterLight />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Lunch Bento") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterLunch />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="lunchbento" src="https://cdn.sanity.io/images/pda0uygu/production/d44b93b8bd8f137c37bb65c6941c5dec6e235577-3591x2825.jpg?w=1000" />
          </div>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  if (context == "Nigiri Sushi") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterNigiri />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Noodle Dishes") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterNoodle />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="noodledishes" src="https://cdn.sanity.io/images/pda0uygu/production/6a0a537ddee535a90d4098c5ed4f237a840559ed-4032x3024.jpg?w=1000" />
          </div>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Pineapple Rice") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterPineapple />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="pineapplerice" src="https://cdn.sanity.io/images/pda0uygu/production/2c6ec5df7088b6ee752951b3a671385397901725-4032x3024.jpg?w=1000" />
          </div>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Poke Bowl") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterPoke />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="pokebowl" src="https://cdn.sanity.io/images/pda0uygu/production/089fede22cb3119f28207c996214f70d9e7b4735-2131x1680.jpg?w=1000" />
          </div>
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  if (context == "Raw Maki Sushi") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterRaw />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
          <div class="inner">
            <img id="rawmakisushi" src="https://cdn.sanity.io/images/pda0uygu/production/1ddbfe92bca5e0c92c05434ed39f0e11a0f96371-3000x2250.jpg?w=1000" />
          </div>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Soft Drinks & Tea") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilterSoft />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Soups & Salads") {
    return (
      <>
        <Logo />
        <br />
        {/* <CategoryFilter activeCategory={pageContext.category} /> */}
        <CategoryFilterSoups />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Vegetarian") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Wine") {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else {
    return (
      <>
        <Logo />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >All Items</h1>
        <br />

        <br />
        <ItemsList items={items} />
      </>
    );
  }
}

export const query = graphql`
        query ItemsQuery2($categoryRegex: String) {
          items: allSanityItems (
        filter: {category: {elemMatch: {name: {regex: $categoryRegex } } } }
        sort: {fields: _createdAt, order: ASC }
        ) {
          nodes {
          name
      _createdAt
        id
        price
        description
        slug {
          current
        }
        category {
          id
        name
      }
    }
  }
}
`;
